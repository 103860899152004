@import url('https://fonts.googleapis.com/css?family=Bellota+Text|Baskervville');

body {
  margin: 0;
  padding: 0;
  font-family: 'Baskervville', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ivory;
  background-color: black;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bellota Text', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media print {
  * {
    color: black !important;
  }
  body {
    font-size: 12pt;
  }
  h1 {
    font-size: 1.80em;
  }
  h2 {
    font-size: 1.30em;
  }
  h3 {
    font-size: 1.25em;
  }
  h4 {
    font-size: 1.20em;
  }
  h0 {
    font-size: 1.15em;
  }
  h6 {
    font-size: 1.10em;
  }
  a {
    text-decoration: none !important;
  }
  .row, .col, div {
    background-image: none !important;
    background: none !important;
  }
}
