.video { 
  position: fixed;
  top: 60%;
  left: 60%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  /*Slight rotation counter-clockwise to "level" horizon.*/
  /*-webkit-transform: translateX(-60%) translateY(-60%) rotate(-0.8deg) scale(1.029, 1.029);*/
  /*-moz-transform: translateX(-60%) translateY(-60%) rotate(-0.8deg) scale(1.029, 1.029);   */
  /*-ms-transform: translateX(-60%) translateY(-60%) rotate(-0.8deg) scale(1.029, 1.029);    */
  /*-o-transform: translateX(-60%) translateY(-60%) rotate(-0.8deg) scale(1.029, 1.029);     */
  /*transform: translateX(-60%) translateY(-60%) rotate(-0.8deg) scale(1.029, 1.029);        */
  -webkit-transform: translateX(-60%) translateY(-60%) scale(1.029, 1.029);
  -moz-transform: translateX(-60%) translateY(-60%) scale(1.029, 1.029);   
  -ms-transform: translateX(-60%) translateY(-60%) scale(1.029, 1.029);    
  -o-transform: translateX(-60%) translateY(-60%) scale(1.029, 1.029);     
  transform: translateX(-60%) translateY(-60%) scale(1.029, 1.029);        
}
.videoButton {
  position: fixed;
  left: 0px;     
  bottom: 0px;    
  cursor: pointer;
}
.copy {
  background: rgba(0, 0, 0, 0.8);
}
.copy a {
  color: dodgerblue;
}
/*Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) { 
  .copy {
    background: rgba(0, 0, 0, 0.6);
  }
  .copyContainer {
    background-image: url('background.png');
    background-size: cover;
  }
}
